import React, {Component} from 'react';

export default class Clients extends Component {
    render(){
        return (
            <>
                <section className="clients-section-two" style={{backgroundColor: '#072948FF'}}>

                    <div className="auto-container">

                        <div className="sponsors-outer">

                            <div className="row">

                                <div className="client-block col-xl-2 col-lg-3 col-md-4 col-sm-6">

                                    <figure className="image-box"><a href="#"><img src="../../images/clients/oracle.png"
                                                                                   alt=""/></a></figure>

                                </div>


                                <div className="client-block col-xl-2 col-lg-3 col-md-4 col-sm-6">

                                    <figure className="image-box"><a href="#"><img src="../../images/clients/mslogo.png"
                                                                                   alt=""/></a></figure>

                                </div>


                                <div className="client-block col-xl-2 col-lg-3 col-md-4 col-sm-6">

                                    <figure className="image-box"><a href="#"><img src="../../images/clients/google.png"
                                                                                   alt=""/></a></figure>

                                </div>


                                <div className="client-block col-xl-2 col-lg-3 col-md-4 col-sm-6">

                                    <figure className="image-box"><a href="#"><img src="../../images/clients/IBM_logo_in.png"
                                                                                   alt=""/></a></figure>

                                </div>


                                <div className="client-block col-xl-2 col-lg-3 col-md-4 col-sm-6">

                                    <figure className="image-box"><a href="#"><img src="../../images/clients/ieee.png"
                                                                                   alt=""/></a></figure>

                                </div>


                                <div className="client-block col-xl-2 col-lg-3 col-md-4 col-sm-6">

                                    <figure className="image-box"><a href="#"><img src="../../images/clients/HP-logo.png"
                                                                                   alt=""/></a></figure>

                                </div>


                                <div className="client-block col-xl-2 col-lg-3 col-md-4 col-sm-6">

                                    <figure className="image-box"><a href="#"><img src="../../images/clients/Intel_logo.png"
                                                                                   alt=""/></a></figure>

                                </div>


                                <div className="client-block col-xl-2 col-lg-3 col-md-4 col-sm-6">

                                    <figure className="image-box"><a href="#"><img src="../../images/clients/phase3.png"
                                                                                   alt=""/></a></figure>

                                </div>


                                <div className="client-block col-xl-2 col-lg-3 col-md-4 col-sm-6">

                                    <figure className="image-box"><a href="#"><img src="../../images/clients/Zenith_Bank_Logo.png" alt=""/></a>
                                    </figure>

                                </div>


                                <div className="client-block col-xl-2 col-lg-3 col-md-4 col-sm-6">

                                    <figure className="image-box"><a href="#"><img src="../../images/clients/abbey.png" alt=""/></a>
                                    </figure>

                                </div>


                                <div className="client-block col-xl-2 col-lg-3 col-md-4 col-sm-6">

                                    <figure className="image-box"><a href="#"><img src="../../images/clients/jaiz-bank-logo.png" alt=""/></a>
                                    </figure>

                                </div>

                                <div className="client-block col-xl-2 col-lg-3 col-md-4 col-sm-6">

                                    <figure className="image-box"><a href="#"><img src={require("../../images/clients/stanic.png")} alt=""/></a></figure>

                                </div>

                            </div>

                        </div>

                    </div>

                </section>
            </>
        );
    }
}