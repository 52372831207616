import React, {Component, useState} from 'react';
import {serverLink} from "../../resources/url";
import {connect} from "react-redux";
import Schedule from "../mainpage/schedule";
import Footer from "../mainpage/footer";
import AgendaContent from "./agenda-content";
import {ThreeDots} from "react-loader-spinner";


function Agenda (props) {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            {
                isLoading ?
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        margin: '-25px 0 0 -25px'
                    }}>
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="red"
                            ariaLabel="loading"
                            wrapperStyle
                            wrapperClass
                        />
                    </div>

                     :
                    <>
                        <AgendaContent eventsList={props.eventList} speakers={props.speakersList} session={props.trackList}  sessionList={props.sessionList}    participant={props.participantList}  />
                        <Footer />
                    </>

            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        eventList: state.EventDetails,
        committeeList: state.CommitteeDetails,
        speakersList: state.SpeakersDetails,
        trackList: state.HomepageContentDetails,
        participantList: state.GeneralDetails,
        partnersList: state.PartnersDetails,
        sessionList: state.SessionDetails,
    };
};

export default connect(mapStateToProps, null)(Agenda);
