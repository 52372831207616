import React from 'react';
import {serverLink} from "../../resources/url";
import {Link} from "react-router-dom";

export default function AboutSection(props) {
    return (
        <>
            <section className="contact-section padding-tb bg-white">
                <div className="container container-1310">
                    <div className="section-wrapper row">
                        <div className="col-lg-6 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".2s">
                            <span
                                style={{fontFamily: 'Teko', fontSize: '11px', lineHeight: '3.0'}}>ABOUT THE EVENT</span>
                            <h2 style={{fontWeight: 'bold'}}><span style={{fontWeight: 'normal'}}>What is </span>IT
                                Research Innovation <br/>& Entrepreneurship Week</h2>
                            <p style={{textAlign: 'justify'}}>
                                The Baze University's Information Technology, Research, Innovation, and Entrepreneurship
                                Week is a conference designed to unite students, researchers, technologists, and
                                innovators from diverse fields to exchange innovative ideas, achievements, and
                                challenges in tech. The theme for this year’s conference is "Inclusive Entrepreneurship
                                for Sustainable Job Creation & Digital Innovation in the 21st Century," and it will be
                                at the core of a wide range of interactive activities throughout the week.<br/><br/>

                                This 4-day program will feature workshops, a hackathon, a job fair, internship
                                opportunities, keynote and power talks, as well as exhibitions showcasing projects by
                                Baze University students and staff. Additionally, there will be engagement with
                                secondary schools. This year's event will incorporate capacity-building workshops on
                                topics such as Generative AI, research and grants management, digital innovation
                                solutions, and entrepreneurship ventures.
                            </p>
                            <div className="btn-wrapper left mt-3">
                                <Link to="/about" className="btn-defult" style={{backgroundColor: '#2b2929', color: 'white'}}>Read More</Link>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-3">
                            <img className="img-fluid h-100" src={require("../../images/banner/event.png")}/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}



