import { setHomepageContentDetails } from "../actions/setactiondetails";
import {projectCode} from "../resources/constants";



export const EventDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_EVENT_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const CommitteeDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_COMMITTEE_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const SpeakersDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_SPEAKERS_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const GeneralDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_GENERAL_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const HomepageContentDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_HOMEPAGE_CONTENT_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};
export const PartnersDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_PARTNERS_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};

export const SessionReducer = (state = [], action) => {
  switch (action.type) {
    case `SET_${projectCode}_SESSION_DETAILS`:
      return action.payload;
    default:
      return state;
  }
};