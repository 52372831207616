import React, {Component} from 'react';
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import Select from 'react-select';
export default function NonSpeakersForm (props) {

    return (
        <>
            <div className="col-lg-6 col-md-6 col-sm-12 form-group mt-3">
                <input type="text" name="FirstName" placeholder="First Name" value={ props.formData.FirstName} onChange={props.onEdit} required=""/>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 form-group  mt-3">
                <input type="text" name="MiddleName"  value={props.formData.MiddleName} onChange={props.onEdit}  placeholder="Middle Name"/>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <input type="text" name="LastName"  value={props.formData.LastName} onChange={props.onEdit}  placeholder="Surname" required=""/>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                <input type="number" name="Phone" value={props.formData.Phone} onChange={props.onEdit}  placeholder="Phone" required=""/>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                <input type="email" name="Email"  value={props.formData.Email} onChange={props.onEdit}  placeholder="Email" />
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <input type="text" name="Organization"  value={props.formData.Organization} onChange={props.onEdit}  placeholder="Organization"/>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <select name="ModeOfParticipation" className="form-control"  value={props.formData.ModeOfParticipation} onChange={props.onEdit} >
                    <option>Select Mode of Participation</option>
                    <option value="Online">Online</option>
                    <option value="Offline">Offline</option>
                    <option value="Hybrid">Hybrid</option>
                </select>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <select name="Gender"  className="form-control" value={props.formData.Gender} onChange={props.onEdit} >
                    <option>Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                </select>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <select name="ParticipantType"  className="form-control" value={props.formData.ParticipantType} onChange={props.onEdit} >
                    <option value="">Select Participant Type</option>
                    <option value="Student">Student</option>
                    <option value="Partner">Partner</option>
                    <option value="Keynote Speaker">Keynote Speaker</option>
                    <option value="Power Talk">Power Talk</option>
                    <option value="Exhibitor/Job Fair">Exhibitor/Job Fair</option>
                    <option value="Hackathon">Hackathon</option>
                    <option value="Baze University Staff">Baze University Staff</option>
                    <option value=" Baze University Student"> Baze University Student</option>
                    <option value="Other">Other</option>
                </select>
            </div>


            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <Select2
                    id="participant_session"
                    name="participant_session"
                    data={props.sessionList}
                    defaultValue={props.selectedSession}
                    multiple
                    className={"custom-select form-control"}
                    onSelect={props.onEdit}
                    options={{
                        placeholder: "Search Session",
                    }}
                />
                {/*<select name="days_of_participation"   onChange={this.onEdit}  multiple style={{height: '100px'}}>*/}
                {/*    <option>Select Days Of Participation</option>*/}
                {/*    <option value="Day 1">Day 1</option>*/}
                {/*    <option value="Day 2">Day 2</option>*/}
                {/*    <option value="Day 4">Day 4</option>*/}
                {/*    <option value="Day 5">Day 5</option>*/}
                {/*</select>*/}
                <label>Hold down “Control”, or “Command” on a Mac, to select more than one.</label>
            </div>

            <div className="submit-btn wow fadeInUp form-group" data-wow-duration="1s" data-wow-delay=".2s">
                <div className="col-md-12">
                    <button className="btn-info w-100 form-control" type="submit" value="Submit-Messege" > Register
                    </button>
                </div>
            </div>

        </>
    );
}
