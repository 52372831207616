import React, { useEffect, useState } from "react";
import {
  setCommitteeDetails,
  setEventDetails, setGeneralDetails,
  setHomepageContentDetails, setPartnersDetails, setSessionDetails, setSpeakersDetails,
} from "./actions/setactiondetails";
import { connect, Provider  } from "react-redux";
import axios from "axios";
import { serverLink } from "./resources/url";
import 'swiper/swiper-bundle.css';
import SwiperCore, { Autoplay } from 'swiper';
import PageRoutes from "./component/pageroutes/pageroutes";
import DataLoader from "./component/common/dataLoader/dataLoader";

function App(props) {
  SwiperCore.use([Autoplay])
  const [toggled, setToggled] = useState("");
  const [isOpened, setIsOpened] = useState("");
  const [isLoading, setIsLoading] = useState(
      !(!( props.eventList.length > 0 && props.committeeList.length > 0 && props.speakersList.length > 0 && props.trackList.length > 0 && props.participantList.length > 0 && props.partnersList.length > 0))
  );

  const toggle = () =>{
    const res = toggled === "" ? "close"  : "";
    const togl = isOpened === "" ? "show-menu"  : "";
    setToggled(res)
    setIsOpened(togl)
  }

  const getData = async () => {
    await axios.get(`${serverLink}homepage/data`).then((result) => {
          const data = result.data;
          if (data.message === "success"){
            let eventData = data.event;
            let participantData = data.participant;
            let committeeData = data.committee;
            let speakersData = data.speakers;
            let trackData = data.track;
            let partnersData = data.partners;
            let sessionData = data.session;
            props.setOnEventDetails(eventData);
            props.setOnGeneralDetails(participantData);
            props.setOnCommitteeDetails(committeeData);
            props.setOnSpeakersDetails(speakersData);
            props.setOnHomepageContentDetails(trackData);
            props.setPartnersDetails(partnersData);
            props.setOnSessionDetails(sessionData);
          }
        }).catch((error) => {
          console.log("error", error);
        });
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
      <div className={`App ${isOpened}`}>
        {isLoading ? <DataLoader /> : <PageRoutes eventList={props.eventList} toggled={toggled} toggle={toggle} />}
      </div>
  );
}

const mapStateToProps = (state) => {
  return {
    eventList: state.EventDetails,
    committeeList: state.CommitteeDetails,
    speakersList: state.SpeakersDetails,
    trackList: state.HomepageContentDetails,
    participantList: state.GeneralDetails,
    partnersList: state.PartnersDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnEventDetails: (p) => {
      dispatch(setEventDetails(p));
    },
    setOnCommitteeDetails: (p) => {
      dispatch(setCommitteeDetails(p));
    },
    setOnSpeakersDetails: (p) => {
      dispatch(setSpeakersDetails(p));
    },
    setOnGeneralDetails: (p) => {
      dispatch(setGeneralDetails(p));
    },
    setOnHomepageContentDetails: (p) => {
      dispatch(setHomepageContentDetails(p));
    },
    setPartnersDetails: (p) => {
      dispatch(setPartnersDetails(p));
    },
    setOnSessionDetails: (p) => {
      dispatch(setSessionDetails(p));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
