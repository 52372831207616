import React, {useEffect, useState} from 'react';
import {
    setCommitteeDetails
} from "../../actions/setactiondetails";
import {connect} from "react-redux";
import axios from "axios";
import {serverLink} from "../../resources/url";
import placeholder from "../../images/banner/slider.png";
import {Link} from "react-router-dom";
import {ThreeDots} from "react-loader-spinner";

function About(props) {
    const [isLoading, setIsLoading] = useState(props.committeeData.length > 0 ? false : true);
    const [committeeList, setCommitteeList] = useState(props.committeeData.length > 0 ? props.committeeData : []);

    const getData = async () => {
        await axios.get(`${serverLink}homepage/data`).then((result) => {
            const data = result.data;
            if (data.message === "success") {
                let committeeData = data.committee;
                setCommitteeList(committeeData)
                props.setOnCommitteeDetails(committeeData);
            }
        }).catch((error) => {
            console.log("error", error);
        });
        setIsLoading(false);
    };
    useEffect(() => {
        getData();
    }, []);

    return (isLoading ?
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                margin: '-25px 0 0 -25px'
            }}>
                <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="red"
                    ariaLabel="loading"
                    wrapperStyle
                    wrapperClass
                />
            </div>

            :
            <>
                <p></p>
                <div class="page-header-section post-title style-1  padding-tb"
                     style={{backgroundImage: `url(${placeholder})`, marginTop: '100px'}}>
                    <div class="overlay">
                        <div class="page-header-content mt-5 pt-5 ">
                            <div class="container container-1310 mt-5 pt-5">
                                <div className="page-header-content-inner">
                                    <div className="page-title">
                                        <span className="title-text">About The Conference</span>
                                    </div>
                                    <ol className="breadcrumb">
                                        <li>You Are Here :</li>
                                        <li><Link to="/">Home</Link></li>
                                        <li className="active">About The Conference</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="event-venues-details style-1  bg-ash">
                    <div class="container container-1310 pb-4">

                        <div class="event-details-wraper no-gutters">
                            <div class="col-xl-8 col-12 ">
                                <div class="events-content">
                                    <h4 className="mb-3">Baze University IT, Research, Innovation & Entrepreneurship
                                        Week</h4>
                                    <p className="wow fadeInUp text-justify" data-wow-duration="1s"
                                       data-wow-delay=".3s">
                                        The Baze University's Information Technology, Research, Innovation, and
                                        Entrepreneurship
                                        Week is a conference designed to unite students, researchers, technologists, and
                                        innovators from diverse fields to exchange innovative ideas, achievements, and
                                        challenges in tech. The theme for this year’s conference is "Inclusive
                                        Entrepreneurship
                                        for Sustainable Job Creation & Digital Innovation in the 21st Century," and it
                                        will be
                                        at the core of a wide range of interactive activities throughout the
                                        week.<br/><br/>

                                        This 4-day program will feature workshops, a hackathon, a job fair, internship
                                        opportunities, keynote and power talks, as well as exhibitions showcasing
                                        projects by
                                        Baze University students and staff. Additionally, there will be engagement with
                                        secondary schools. This year's event will incorporate capacity-building
                                        workshops on
                                        topics such as Generative AI, research and grants management, digital innovation
                                        solutions, and entrepreneurship ventures.<br/><br/>

                                        There will be round table discussions featuring local entrepreneurs from our
                                        network, a pitch competition, an awards ceremony, and interactions with local
                                        entrepreneurship agencies and national and international organizations. The
                                        primary goal of this year's event is to promote a more inclusive and sustainable
                                        environment for entrepreneurship, research, and innovation. The aim is to
                                        inspire and prepare our youth to become leaders in the innovation ecosystem,
                                        contributing to the growth of our economy. It also seeks to ignite the
                                        aspirations of young individuals and encourage them to become leaders in the
                                        innovation ecosystem, which will drive the economic development of our nation.
                                        To register for this event, please visit <a className="link" href="https://conference.bazeuniversity.edu.ng/register" target="_blank">https://conference.bazeuniversity.edu.ng/register</a>. Join us in
                                        building inclusive entrepreneurship for sustainable job creation and digital
                                        innovation in the 21st century.
                                    </p>
                                </div>
                            </div>
                            <div class="col-xl-4 col-12">
                                <div class="events-sidebar">
                                    <div class="venue-info">
                                        <h5>Venue Info</h5>
                                        <ul>
                                            <li>
                                                <p>location</p>:<span>Baze Univerisity, Abuja Nigeria.</span>
                                            </li>
                                            <li>
                                                <p>Email</p>:<span>itri@bazeuniversity.edu.ng</span>
                                            </li>
                                            <li>
                                                <p>Address</p>:<span>Plot 686, Jabi Airport Road Bypass, Cadastral Zone, Abuja</span>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/*<section className="about-section style-2 padding-tb">*/}
                {/*    <div className="container container-1310">*/}

                {/*        <div className="section-wrapper">*/}
                {/*            <div className="row">*/}
                {/*                <div className="col-lg-6 col-12">*/}
                {/*                    <div className="about-item-left">*/}
                {/*                        /!*<div className="section-header">*!/*/}
                {/*                        /!*    <h4>The No. 1 Event Management Company</h4>*!/*/}
                {/*                        /!*</div>*!/*/}
                {/*                        <div className="about-content">*/}
                {/*                            <p className="text-justify">*/}
                {/*                                The Baze University International and Exhibition on Sustainable*/}
                {/*                                Entrepreneurship and Leadership Development is an event organized in collaboration with the Society for Economic Empowerment and Entrepreneurship Development, scheduled to hold within the Baze University Entrepreneurship and Digital Innovation Week.*/}
                {/*                                The even is designed to be a brainstroming lab that seeks to redefine the approach to sustainable entrepreneurship and quality leadership development, for broad based*/}
                {/*                                productivity across all spheres of human, societal and national endeavours, while ensuring that economic issues and ground realities will be crafted and reflected in the new way of thinking and doing business.*/}
                {/*                            </p>*/}

                {/*                        </div>*/}
                {/*                        <div className="about-counter feature-rsb d-md-flex">*/}
                {/*                            <div className="rsb-list">*/}
                {/*                                <div className="rsb-icon"><span className="fa fa-user-friends"/></div>*/}
                {/*                                <span className="counter">{props.speakersList.length}</span>*/}
                {/*                                <p>Speakers </p>*/}
                {/*                            </div>*/}
                {/*                            <div className="rsb-list">*/}
                {/*                                <div className="rsb-icon"><span className="fa fa-file-signature"/></div>*/}
                {/*                                <span className="counter">{props.trackList.length}</span>*/}
                {/*                                <p>Topic & Solutions</p>*/}
                {/*                            </div>*/}
                {/*                            <div className="rsb-list">*/}
                {/*                                <div className="rsb-icon"><span className="fa fa-users"/></div>*/}
                {/*                                <span className="counter">{props.participantList.length}</span>*/}
                {/*                                <p>Participant </p>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*                <div className="col-lg-6 col-12">*/}
                {/*                    <img src={banner} alt="Banner"/>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
            </>
    );
}

const mapStateToProps = (state) => {
    return {
        eventList: state.EventDetails,
        committeeData: state.CommitteeDetails,
        speakersList: state.SpeakersDetails,
        trackList: state.HomepageContentDetails,
        participantList: state.GeneralDetails,
        partnersList: state.PartnersDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnCommitteeDetails: (p) => {
            dispatch(setCommitteeDetails(p));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(About);
