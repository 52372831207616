import React, {Component} from 'react';
import {Link} from "react-router-dom";
import img1 from "../../images/banner/slider.png";
import {Carousel} from "react-responsive-carousel";
import "./slider.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
export default function Banner(props){
        return (<section className=" " style={{marginTop: '0px', paddingTop: '10px !important'}}>

                <Carousel infiniteLoop={true} interval={5000} autoPlay={true} renderThumbs={() => {}}>
                    <div style={{marginTop: '80px'}} >
                        <img className="img-fluid" src={img1} style={{maxHeight:'650px'}} />
                        <div className="captionText">
                            <div className="text-center">
                                <div className="main_text" ><h4 className="banner-title" style={{color: '#FFFFFF'}}>{props.eventsList.Title}</h4> </div>
                                <div style={{fontSize: '18px'}}>
                                    Theme: {props.eventsList.Theme}
                                </div>
                                <div className="btn-wrapper left mt-3">
                                    <Link to="/register" className="btn-defult" style={{backgroundColor: 'white', color: 'black'}}>Register for the event</Link>
                                </div>
                            </div>
                        </div>

                    </div>

                </Carousel>
            </section>

        );
}
