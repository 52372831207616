import React, {Component, useEffect, useState} from 'react';
import {
    setCommitteeDetails,
    setEventDetails,
    setGeneralDetails, setHomepageContentDetails,
    setSpeakersDetails
} from "../../actions/setactiondetails";
import {connect} from "react-redux";
import axios from "axios";
import {serverLink} from "../../resources/url";
import placeholder from "../../images/speaker/placeholder.png";

 function Committee (props) {
     const [isLoading, setIsLoading] = useState(props.committeeData.length > 0 ? false : true);
     const [committeeList, setCommitteeList] = useState(props.committeeData.length > 0 ? props.committeeData : []);

     const getData = async () => {
         await axios.get(`${serverLink}homepage/data`).then((result) => {
             const data = result.data;
             if (data.message === "success"){
                 let committeeData = data.committee;
                 setCommitteeList(committeeData)
                 props.setOnCommitteeDetails(committeeData);
             }
         }).catch((error) => {
             console.log("error", error);
         });
         setIsLoading(false);
     };
     useEffect(() => {
         getData();
     }, []);

    return (
        <>
            {
                isLoading ? <></> :  <section className="speaker-section style-1 padding-tb bg-ash">
                    <div className="container container-1310 mt-5">
                        <div className="section-header sticky-widget">
                            <h2 className="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".1s">COMMITTEE MEMBERS</h2>
                            <p className="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">Planning and Implementation Committee</p>
                        </div>
                        <div className="section-wrapper d-flex flex-wrap justify-content-lg-center">
                            {
                                committeeList.length > 0 && committeeList.map((item, index)=>{
                                    return (
                                        <div key={index} className="speaker-item wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".1s">
                                            <div className="speaker-item-inner">
                                                <div className="item-thumb">
                                                    <img src={!item.Photo ? placeholder : item.Photo.includes("simplefileupload") ? item.Photo : `${serverLink}public/uploads/conference/commitee/${item.Photo}`} alt="speaker"/>
                                                    <div className="item-overlay"/>
                                                    <div className="social-profile">
                                                        <div className="icon facebook">
                                                            <a href="https://web.facebook.com/" target="_blank"><i className="fab fa-facebook-f"/></a>
                                                        </div>
                                                        <div className="icon twitter">
                                                            <a href="https://www.twitter.com/" target="_blank"><i className="fab fa-twitter"/></a>
                                                        </div>
                                                        <div className="icon google">
                                                            <a href="https://www.google.com/" target="_blank"><i className="fab fa-google-plus-g"/></a>
                                                        </div>
                                                        <div className="icon linkedin">
                                                            <a href="https://www.linkedin.com/" target="_blank"><i className="fab fa-linkedin-in"/></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item-content">
                                                    <a href="#" className="name">{item.FullName} </a>
                                                    <p>{item.Designation} </p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </section>
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        committeeData: state.CommitteeDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnCommitteeDetails: (p) => {
            dispatch(setCommitteeDetails(p));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Committee);