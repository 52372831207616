import storage from "redux-persist/lib/storage";
import {
  CommitteeDetailsReducer,
  EventDetailsReducer,
  GeneralDetailsReducer,
  HomepageContentDetailsReducer, PartnersDetailsReducer, SessionReducer, SpeakersDetailsReducer,
} from "./detailsReducer";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import {projectCode} from "../resources/constants";

const rootReducer = combineReducers({
  GeneralDetails: GeneralDetailsReducer,
  HomepageContentDetails: HomepageContentDetailsReducer,
  EventDetails: EventDetailsReducer,
  CommitteeDetails: CommitteeDetailsReducer,
  SpeakersDetails: SpeakersDetailsReducer,
  PartnersDetails: PartnersDetailsReducer,
  SessionDetails: SessionReducer
});

const persistConfig = {
  key: projectCode.toLowerCase(),
  storage,
};

export default persistReducer(persistConfig, rootReducer);
