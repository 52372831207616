import React, {Component} from 'react';
import {projectLogo} from "../../../resources/constants";
import {
    Link,

} from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';


export default function Header(props) {
    const agenda = () => {
      document.getElementById("agenda").scrollIntoView();
    }

    const speakers = () => {
        document.getElementById("speakers").scrollIntoView();
    }
        return (
            <>
                <div className="mobile-menu">
                    <nav className={`mobile-header primary-menu d-lg-none`}>
                        <div className="header-logo">
                            <a href="/" className="logo"><img   width="60px" height="60px" src={projectLogo}  alt="logo"/></a>
                        </div>
                        <div className="header-bar" id="open-button" onClick={props.toggle}>
                            <span/>
                            <span/>
                            <span/>
                        </div>
                    </nav>
                    <div className="menu-wrap">
                        <div className="morph-shape" id="morph-shape" data-morph-open="M-1,0h101c0,0,0-1,0,395c0,404,0,405,0,405H-1V0z">
                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100 800" preserveAspectRatio="none">
                                <path d="M-1,0h101c0,0-97.833,153.603-97.833,396.167C2.167,627.579,100,800,100,800H-1V0z"/>
                            </svg>
                        </div>
                        <nav className="menu">
                            <div className={`mobile-menu-area d-lg-none`}>
                                <div className="mobile-menu-area-inner" id="scrollbar" onClick={props.toggle}>
                                    <div className="header-bar m-menu-bar">
                                        <a href="/" className="logo"><img src={projectLogo} alt="logo"/></a>
                                        <div className="close-button" id="open-button"/>
                                    </div>
                                    <li className="active"><a href="/">Home</a></li>
                                    <li><a href="/agenda">Agenda</a></li>
                                    <li><a href="/speakers">Speakers</a></li>
                                    <li><a href="/committee">Committee Members</a></li>
                                    <li><a
                                         href="/home#partners"
                                        onClick={() => {
                                            window.scrollTo({
                                                top: document.documentElement.scrollHeight,
                                                left: 0,
                                                behavior: 'smooth'
                                            });
                                            // setTimeout(()=>{
                                            //     window.scrollTo({
                                            //         top: document.documentElement.scrollHeight,
                                            //         left: 0,
                                            //         behavior: 'smooth'
                                            //     });
                                            // }, 1000)
                                        }}
                                    >
                                        Partners
                                    </a></li>
                                    <li><a href="/register">Register</a></li>
                                    <li>
                                        <a href="#0">About</a>
                                        <ul className="submenu">
                                            <li><a href="/about-itri">ITRI Office</a></li>
                                            <li><a href="/about">The Conference</a></li>
                                        </ul>
                                    </li>

                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="header-section d-none d-lg-block">
                    <div className="header-bottom">
                        <nav className="primary-menu">
                            <div className="container container-1310">
                                <div className="menu-area">
                                    <div className="row no-gutters justify-content-between align-items-center">
                                        <Link to="/" className="logo">
                                            <img  width="60px" height="60px" src={projectLogo}  alt="logo"/>
                                                <img  width="60px" height="60px" src={projectLogo} alt="logo"/>
                                        </Link>
                                        <div className="right d-flex align-items-center">
                                            <ul className="main-menu d-flex align-items-center">
                                                <li className=""><Link to="/">Home</Link></li>
                                                <li>
                                                    <a href="#0">About</a>
                                                    <ul className="submenu">
                                                        <li><Link to="/about-itri">ITRI Office</Link></li>
                                                        <li><Link to="/about">The Conference</Link></li>
                                                    </ul>
                                                </li>
                                                <li><a href="/agenda">Agenda</a></li>
                                                <li><Link to="/speakers">Speakers</Link></li>
                                                <li><Link to="/committee">Committee Members</Link></li>
                                                <li><Link
                                                    to="/"
                                                    onClick={() => {
                                                            window.scrollTo({
                                                                top: document.documentElement.scrollHeight,
                                                                left: 0,
                                                                behavior: 'smooth'
                                                            });
                                                            setTimeout(()=>{
                                                                window.scrollTo({
                                                                    top: document.documentElement.scrollHeight,
                                                                    left: 0,
                                                                    behavior: 'smooth'
                                                                });
                                                            }, 1000)
                                                    }}
                                                >
                                                    Partners
                                                </Link>
                                                </li>
                                                {/*<li><a href="http://localhost:3000/#agenda-section">Agenda</a></li>*/}
                                            </ul>
                                            <a className="reg-head d-none d-xl-block" style={{ backgroundColor: "#2b2929", color: "white", borderColor: "#2b2929"}}  target="_blank" href={!props.eventList ? "" : props.eventList.OnlineLink}>Join live event</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </>
        );
}
