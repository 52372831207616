import React, {Component, useEffect, useState} from 'react';
 import {ThreeDots} from "react-loader-spinner";
import axios from "axios";
import {paymentAPIKey, sendMail, serverLink} from "../../resources/url";
import NonSpeakersForm from "./sections/non-speakers-form";
import {showAlert} from "../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import SpeakersForm from "./sections/speakers-form";
import { usePaystackPayment } from 'react-paystack';
import {DASHBOARD, encryptData, mailerConfig} from "../../resources/constants";


 function Registration () {
     const [isLoading, setIsLoading] = useState(true);
     const [sessionList, setSessionList] = useState([]);
     const [selectedSession, setSelectedSession] = useState([]);
     const [conferenceAmount, setConferenceAmount] = useState(0);
     const  [formData, setFormData] = useState({
         FirstName: "",
         MiddleName: "",
         LastName: "",
         Email: "",
         Phone: "",
         Organization: "",
         Gender: "",
         Password: "",
         CPassword: "",
         participationType: "",
         ParticipantType: "",
         Region: "",
         participant_session: [],
         ModeOfParticipation: "",
         Description: 'Registration Fee',
         Amount: 0
     })

useEffect(()=>{
    fetchSession();
}, [""])


     const  fetchSession = async () => {
        await axios.get(`${serverLink}session/list/session`)
            .then((result) => {
                if (result.data.length > 0) {
                    let rows = [];
                    result.data.map((item => {
                        rows.push({ id: item.EntryID, text: `${item.Title}` })
                    }))
                    setSessionList(rows)
                }
                setIsLoading(false)
            })
    }

   const onEdit = (e) => {
       const options = e.target.options;
       const value = [];
       let amountPayable = 0;

       if (e.target.id === "Region") {
           document.getElementById('amount_payable').style.display = 'block';
           let index = e.nativeEvent.target.selectedIndex;
           let physical_amount = e.nativeEvent.target[index].getAttribute('amount');
           let online_amount = e.nativeEvent.target[index].getAttribute('data');
           if (e.target.value === "Local (Nigerian) Participants"){
               if (formData.ModeOfParticipation === "Online")
                   amountPayable = parseFloat(online_amount);
               else
                   amountPayable = parseFloat(physical_amount);

           }else if (e.target.value === "African Participants"){
               if (formData.ModeOfParticipation === "Online")
                   amountPayable = parseFloat(online_amount);
               else
                   amountPayable = parseFloat(physical_amount);
           }else if (e.target.value === "Foreign Participants"){
               if (formData.ModeOfParticipation === "Online")
                   amountPayable = parseFloat(online_amount);
               else
                   amountPayable = parseFloat(physical_amount);
           }else{
               document.getElementById('amount_payable').style.display = 'none';
               amountPayable = 0;
           }
           setConferenceAmount(amountPayable)
           setFormData({
               ...formData,
               Amount: amountPayable
           });
       }

       else if (e.target.id === "ModeOfParticipation") {
           document.getElementById('amount_payable').style.display = 'none';
           document.getElementById('ParticipantType').value = "";
           amountPayable = 0;
           setFormData({
               ...formData,
               ParticipantType: "",
               Region: "",
           });
       }


       if (e.target.id === "participant_session") {
           for (var i = 0, l = options.length; i < l; i++) {
               if (options[i].selected) {
                   if (e.target.id === "participant_session") {
                       value.push(options[i].value);
                   }
               }
           }
           setSelectedSession(value);
       }else {

           setFormData({
               ...formData,
               [e.target.name]: e.target.value,
           });
       }
    };

     const paystackConfig = {
         reference: (new Date()).getTime().toString(),
         email: formData.Email,
         amount: parseFloat(conferenceAmount) * 100,
         publicKey: paymentAPIKey,
         currency: 'NGN',
     };
     const initializePayment = usePaystackPayment(paystackConfig);

     const onPaystackSuccess = async (reference) =>  {
         if (reference.status === 'success' && reference.message === 'Approved') {
             toast.info('Submitting your form. Please wait!');

             let sendData = {...formData, PaymentID: paystackConfig.reference, participant_session: selectedSession, Amount: conferenceAmount, Password: encryptData(formData.Password)}
            await axios.post(serverLink+'participants/participant/add', sendData, { headers: { "Content-Type": "application/json" } }
             ).then(async result =>   {
                     setIsLoading(false);
                     const response = result;
                     if (response.data.message === 'success'){
                         toast.success("Participant Registered Successfully");
                         sendMail(formData.Email, "Participant Registration", "Thank you for your application.");
                         setTimeout(()=>{
                             window.location=`${DASHBOARD}`;
                         }, 3000);

                         setFormData({
                             ...formData,
                             FirstName: "",
                             MiddleName: "",
                             LastName: "",
                             Email: "",
                             Phone: "",
                             Organization: "",
                             Gender: "",
                             Password: "",
                             CPassword: "",
                             participationType: "",
                             ParticipantType: "",
                             Region: "",
                             participant_session: [],
                             ModeOfParticipation: "",
                             Description: 'Registration Fee',
                             Amount: 0
                         });
                     }else if(response.data.message === "exist"){
                         showAlert(
                             "ERROR",
                             "You have already registered!",
                             "error"
                         );
                     }else{
                         showAlert(
                             "ERROR",
                             "Something went wrong. Please try again!",
                             "error"
                         );
                     }

                 })
                 .catch(err => {
                     setIsLoading(false);
                     showAlert(
                         "ERROR",
                         "Network Error. Please try again!",
                         "error"
                     );
                     console.error('ERROR', err);
                 });

         }
     };
     // you can call this function anything
     const onPaystackClose = () => {
         // implementation for  whatever you want to do when the Paystack dialog closed.
         setIsLoading(false)
         console.log('closed')
     }

     const participantForm = (participant) => {
         if (participant === "Academic"){
             return <SpeakersForm formData={formData} onEdit={onEdit} sessionList={sessionList} selectedSession={selectedSession} conferenceAmount={conferenceAmount} />
         }else if(participant === "ITRI"){
             return <NonSpeakersForm formData={formData} onEdit={onEdit} sessionList={sessionList} selectedSession={selectedSession} />
         }else if(participant === "INTRO"){
             return <div className="col-lg-12 col-md-12 col-sm-12 form-group ">
                 <section className="register-section style-1 padding-tb">
                     <div className="container container-1310">
                         <div className="section-wraper">
                             <div className="register"
                                  style={{backgroundImage: 'url(assets/images/register/bg.png)'}}>
                                 <h4>Register & Submit Your Papers</h4>
                                 <p>Follow the steps below to complete your registration process</p>
                                 <ul className="pricing-body text-secondary" style={{listStyle: 'none'}}>
                                     <li>Fill The Form</li>
                                     <li>Make Online Payment</li>
                                     <li>Login To Your Dashboard</li>
                                     <li>Upload Your Papers Before The Deadline</li>
                                 </ul>
                                 <a href="#" onClick={()=>setFormData({...formData, participationType: "Academic"})} className="btn-defult">Continue</a>
                             </div>
                         </div>
                     </div>
                 </section>
             </div>
         }else{
             return <></>;
         }
     }

     const executePayStack = () => {
        return  document.getElementById("pay").click();
     }

     const onAddParticipant = async (e) => {
         e.preventDefault();
         setIsLoading(true);

         if(formData.FirstName.trim() === "") {
             setIsLoading(false);
             showAlert("VALIDATION ERROR","Please Enter First Name", "error");
             return false;
         }

         if(formData.LastName.trim() === "") {
             setIsLoading(false);
             showAlert("VALIDATION ERROR","Please Enter Last Name", "error");
             return false;
         }

         if(formData.Phone.trim().toString() === "") {
             setIsLoading(false);
             showAlert("VALIDATION ERROR","Please Enter Phone Number", "error");
             return false;
         }

         if(formData.Email.trim() === "") {
             setIsLoading(false);
             showAlert("VALIDATION ERROR","Please Enter Email Address", "error");
             return false;
         }

         if(formData.ParticipantType.trim() === "") {
             setIsLoading(false);
             showAlert("VALIDATION ERROR","Please Select Participant Type", "error");
             return false;
         }


         if(formData.Gender.trim() === "") {
             setIsLoading(false);
             showAlert("VALIDATION ERROR","Please Select Gender", "error");
             return false;
         }

         if(formData.participant_session  === []) {
             setIsLoading(false);
             showAlert("VALIDATION ERROR","Please Select Session", "error");
             return false;
         }

         if (formData.participationType === "Academic"){
             toast.info('Submitting your application. Please wait!');
             await axios.post(serverLink+'participants/check', formData, { headers: { "Content-Type": "application/json" } }
             ).then(async result =>   {
                 setIsLoading(false);
                 const response = result;
                 if (response.data.message === 'available'){
                     setTimeout(()=>{
                         executePayStack.call();
                     }, 2000);
                 }else if(response.data.message === "exist"){
                     showAlert(
                         "ERROR",
                         "You have already registered!",
                         "error"
                     );
                 }else{
                     showAlert(
                         "ERROR",
                         "Something went wrong. Please try again!",
                         "error"
                     );
                 }

             })
                 .catch(err => {
                     setIsLoading(false);
                     showAlert(
                         "ERROR",
                         "Network Error. Please try again!",
                         "error"
                     );
                     console.error('ERROR', err);
                 });


         }else{
             let sendData = {...formData, participant_session: selectedSession}
             toast.info('Submitting your application. Please wait!');
            await axios.post(serverLink+'participants/participant/add', sendData, { headers: { "Content-Type": "application/json" } })
                 .then(result => {
                     setIsLoading(false);
                     const response = result;
                     if (response.data.message === 'success'){
                         toast.success("Participant Registered Successfully");
                         setFormData({
                             ...formData,
                             FirstName: "",
                             MiddleName: "",
                             LastName: "",
                             Email: "",
                             Phone: "",
                             Organization: "",
                             Gender: "",
                             Password: "",
                             CPassword: "",
                             participationType: "",
                             ParticipantType: "",
                             Region: "",
                             participant_session: [],
                             ModeOfParticipation: "",
                             Description: 'Registration Fee',
                             Amount: 0
                         });
                        setTimeout(()=>{
                            window.location.href ='/agenda';
                        }, 2000)
                     }else if(response.data.message === "exist"){
                         setIsLoading(false);
                         showAlert(
                             "ERROR",
                             "You have already registered!",
                             "error"
                         );
                     }else{
                         setIsLoading(false);
                         showAlert(
                             "ERROR",
                             "Something went wrong. Please try again!",
                             "error"
                         );
                     }

                 })
                 .catch(err => {
                     setIsLoading(false);
                     showAlert(
                         "ERROR",
                         "Network Error. Please try again!",
                         "error"
                     );
                     console.error('ERROR', err);
                 });

         }
     }
     return (
            isLoading ?
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    margin: '-25px 0 0 -25px'
                }}>
                <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="red"
                    ariaLabel="loading"
                    wrapperStyle
                    wrapperClass
                />
                </div>

                :

                <div className="container "  style={{marginTop: '150px'}}>
                    <div className="testimonial-section style-1">
                        <div className="section-heading wow fadeInDown" data-wow-duration="1s" data-wow-delay=".1s">
                            <h2>Participant Registration</h2>
                        </div>
                        <form  onSubmit={onAddParticipant}>
                            <div className="row clearfix">
                                <div className="col-lg-12 col-md-12 col-sm-12 form-group ">
                                    <select name="participationType" id="participationType" className="form-control" onChange={onEdit} >
                                        <option value="">Select Participant Type</option>
                                        {/*<option value="INTRO">Academic Participant</option>*/}
                                        <option value="ITRI">ITRI Participant</option>
                                    </select>
                                </div>

                                {

                                    participantForm(formData.participationType)
                                }
                            </div>
                        </form>
                        <button id="pay" className="btn btn-dark w-100 active" onClick={() => {
                            initializePayment(onPaystackSuccess, onPaystackClose)
                        }} hidden>REGISTER</button>
                    </div>
                </div>

        );
}

export default Registration;
