import React, {Component, useEffect, useState} from 'react';
import {
    setCommitteeDetails
} from "../../actions/setactiondetails";
import {connect} from "react-redux";
import axios from "axios";
import {serverLink} from "../../resources/url";
import rislan from '../../images/banner/itri_vc.jpeg'
import pic from '../../images/pic.png'

function AboutITRI (props) {
    const [isLoading, setIsLoading] = useState(props.committeeData.length > 0 ? false : true);
    const [committeeList, setCommitteeList] = useState(props.committeeData.length > 0 ? props.committeeData : []);

    const getData = async () => {
        await axios.get(`${serverLink}homepage/data`).then((result) => {
            const data = result.data;
            if (data.message === "success"){
                let committeeData = data.committee;
                setCommitteeList(committeeData)
                props.setOnCommitteeDetails(committeeData);
            }
        }).catch((error) => {
            console.log("error", error);
        });
        setIsLoading(false);
    };
    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <p></p>
            <div class="page-header-section post-title style-1  padding-tb" style={{backgroundImage: `url(${rislan})`, marginTop: '100px'}}>
                <div class="overlay">
                    <div class="page-header-content mt-5 pt-5 ">
                        <div class="container container-1310 mt-5 pt-5">
                            <div className="page-header-content-inner">
                                <div className="page-title">
                                    <span className="title-text">ABOUT BAZE ITRI <span> OFFICE</span></span>
                                </div>
                                <ol className="breadcrumb">
                                    <li className="active">BAZE ITRI OFFICE</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="event-venues-details style-1  bg-ash">
                <div class="container container-1310 pb-4">

                    <div class="event-details-wraper no-gutters">
                        <div class="col-xl-8 col-12 ">
                            <div class="mb-4 mt-5">
                                <h4 className="mb-3">  ABOUT BAZE ITRI OFFICE</h4>
                                <p className="wow fadeInUp text-justify" data-wow-duration="1s" data-wow-delay=".3s">
                                    The Information Technology, Research, and Innovation (DVC IT, R & I) office was recently created and placed under the stewardship of a newly appointed DVC IT, R & I, Dr. Rislan Abdulazeez Kanya, to move forward Baze University's Information Technology, Research, and Innovation (IT, R & I) agenda as the institution progresses into its second decade. The DVC IT, R, & I is responsible for the strategic development, leadership, and management of the IT, Research, and Innovation agenda of Baze University. The DVC IT, R & I will also leverage the competitive advantage achieved by Baze University through strategic investment in Digital transformation assets. The digital asset at Baze University is strategically positioned to actualise the Baze philosophy.
                                </p>
                                <p className="wow fadeInUp text-justify" data-wow-duration="1s" data-wow-delay=".3s">
                                    The primary mandate of the IT, R & I office will be to adhere to and uphold the Baze philosophy. Since its inception, Baze University has successfully enrolled students in various undergraduate and postgraduate degree programs accredited by the National Universities Commission (NUC) and other accreditation or regulatory agencies. Also, over the last ten years, Baze has significantly invested in high-quality infrastructure (buildings, equipment, digital assets, etc.), recruited competent staff at various levels, and rolled out disruptive service delivery. This tri-combination of people, processes, and technologies enabled Baze to deliver significant expectations of its founding members, students, parents, and society.
                                </p>
                                <p className="wow fadeInUp text-justify" data-wow-duration="1s" data-wow-delay=".3s">
                                    As Baze progresses into its second decade of excellence and as part of its transformation agenda, the IT, R & I office is ready to exploit all the possibilities of digital transformation beyond building a connected campus for teaching and learning but as an enabler that can make a difference in the research and innovation ecosystem.                                </p>
                            </div>
                            <div className="mb-4">
                                <h4 className="mb-3"> The Baze IT, R & I Office Objectives</h4>
                                <p className="wow fadeInUp text-justify" data-wow-duration="1s" data-wow-delay=".3s">
                                    The IT, R & I office is expected to deliver the following major objectives:<br/>
                                    ▪ Help our students and staff create a positive impact on society from the outputs of our teaching, learning, research, innovation, and community service.<br/>
                                    ▪ Create income for the University to fund research and innovation through grants, donations, and endowments.<br/>
                                    ▪ Conduct quality research and development activities that can lead to Academic Entrepreneurship, i.e., patenting, technology licensing, and venture spinoffs, all with the objective of commercialising research and development results.<br/>
                                    ▪ Transfer innovative initiatives to accelerate sustainable development goals (SDGs).<br/>
                                </p>
                            </div>
                            <div className="mb-4">
                                <h4 className="mb-3"> The Baze IT, R & I Office Agenda</h4>
                                <p className="wow fadeInUp text-justify" data-wow-duration="1s" data-wow-delay=".3s">
                                    following key areas:<br/>
                                    <b>INSTITUTIONAL RESEARCH:</b> Research is one of the core mandates of any university, and this office will initiate and implement valuable research that will majorly focus on ways to:
                                    <ul>
                                        <li>Improve quality undergraduate and postgraduate applications and enrolments.</li>
                                        <li>Reduce dropout rates and improve achievements.</li>
                                        <li>Improve operational efficiency by evaluating and streamlining business processes.</li>
                                        <li>Improve quality assurance and control by ensuring necessary checks and validations for procedural activities in the University.</li>
                                        <li>Improve quality relationships with government, regulators, industries, society, and the environment.</li>
                                    </ul>
                                    <b>GRADUATE EMPLOYABILITY:</b> Graduates find it challenging to gain employment due to various existential threats such as global warming and digitalisation; hence, this office will develop a pathway to help our graduates gain a decent and future job.
                                </p>
                            </div>
                        </div>
                        <div class="col-xl-4 col-12 mt-5">
                            <div class="events-sidebar">
                                <div class="venue-info">
                                    <img className="w-100 img-thumbnail" src={rislan} alt="rislan"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-section style-2 padding-tb">
                <div className="container container-1310">
                    <div className="section-header">
                        <h2>Action Points</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div className="about-item-left">
                                    <div className="about-content">
                                        <p>
                                            The main action points to be initiated by the IT, R & I department to implement its agenda and achieve its goals are as follows:<br/>
                                            <ul>
                                                <li>Setup various committees to develop policies.</li>
                                                <li> Develop measurable KPIs.</li>
                                                <li> Deploy and operationalize interventions.</li>
                                                <li>Capacity development.</li>
                                                <li>Evaluate interventions based on KPIs.</li>
                                            </ul>
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <img src={pic} alt="Pic"/>
                                <h5>Deliverable Committees.</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        committeeData: state.CommitteeDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnCommitteeDetails: (p) => {
            dispatch(setCommitteeDetails(p));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AboutITRI);