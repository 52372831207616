import React, {Component} from 'react';

export default class Futures extends Component {
    render(){
        return (
            <>
                <section className="features-section">

                    <div className="auto-container">

                        <div className="anim-icons">

                            <span className="icon icon-shape-3 wow fadeIn"></span>

                            <span className="icon icon-line-1 wow fadeIn"></span>

                        </div>


                        <div className="row">


                            <div className="feature-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp">

                                <div className="inner-box">

                                    <div className="icon-box"><span className="icon flaticon-search"></span></div>

                                    <h4><a href="about.html">Experience</a></h4>

                                    <div className="text">Dolor sit amet consectetur elit sed do eiusmod tempor incd
                                        idunt labore et dolore magna aliqua.
                                    </div>

                                    <div className="link-box"><a href="about.html" className="theme-btn">Read More</a>
                                    </div>

                                </div>

                            </div>



                            <div className="feature-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
                                 data-wow-delay="400ms">

                                <div className="inner-box">

                                    <div className="icon-box"><span className="icon flaticon-diamond-1"></span></div>

                                    <h4><a href="about.html">Networking</a></h4>

                                    <div className="text">Dolor sit amet consectetur elit sed do eiusmod tempor incd
                                        idunt labore et dolore magna aliqua.
                                    </div>

                                    <div className="link-box"><a href="about.html" className="theme-btn">Read More</a>
                                    </div>

                                </div>

                            </div>



                            <div className="feature-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
                                 data-wow-delay="800ms">

                                <div className="inner-box">

                                    <div className="icon-box"><span className="icon flaticon-success"></span></div>

                                    <h4><a href="about.html">Party</a></h4>

                                    <div className="text">Dolor sit amet consectetur elit sed do eiusmod tempor incd
                                        idunt labore et dolore magna aliqua.
                                    </div>

                                    <div className="link-box"><a href="about.html" className="theme-btn">Read More</a>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </section>
            </>
        );
    }
}