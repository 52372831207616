import React, {Component, useState} from 'react';
import "./mainpage.css";
import Banner from "./banner";
import Futures from "./futures";
import EventCount from "./eventcount";
import Speakers from "./speakers";
import Schedule from "./schedule";
import Clients from "./clients";
import EventInfo from "./eventinfo";
import Footer from "./footer";
import {serverLink} from "../../resources/url";
import {
    setCommitteeDetails,
    setEventDetails,
    setGeneralDetails, setHomepageContentDetails,
    setSpeakersDetails
} from "../../actions/setactiondetails";
import {connect} from "react-redux";
import AgendaContent from "../agenda/agenda-content";
import Slider from "../common/slider/slider";
import AboutSection from "./about-section";


 function MainPage (props) {
        const [isLoading, setIsLoading] = useState(false);
        const [eventsList, setEventsList] = useState([]);
        const [sessionsList, setSessionsList] = useState([]);
        const [speakersList, setSpeakersList] = useState([]);
        const [participantsList, setParticipantsList] = useState([]);


     const fetchHomepageData = async () => {
         await fetch(`${serverLink}homepage/data`)
            .then(res => res.json())
            .then(data => {

            });
    }

        return (
           <>
               {
                   isLoading ?  <></> :
                       <>
                           {/*BANNER*/}
                           <Banner eventsList={props.eventList} />

                           {/*FUTURE*/}
                           {/*<Futures />*/}
                           {/*EVENT COUNT*/}
                           <EventCount  eventsList={props.eventList} participant={props.participantList}  speakers={props.speakersList}  />
                           {/*About*/}
                           <AboutSection  partnersList={props.partnersList} />
                           {/*SPEAKERS*/}
                           <Speakers speakersList={props.speakersList} />
                           {/*SCHEDULE*/}
                           <AgendaContent eventsList={props.eventList} speakers={props.speakersList} session={props.trackList} sessionList={props.sessionList}   participant={props.participantList}  />
                           {/*CLIENTS*/}
                           {/*<Clients />*/}
                           {/*EVENT INFO*/}
                           <EventInfo partnersList={props.partnersList}  />
                           {/*FOOTER*/}
                           <Footer />
                       </>

               }
           </>
        );
}

const mapStateToProps = (state) => {
    return {
        eventList: state.EventDetails,
        committeeList: state.CommitteeDetails,
        speakersList: state.SpeakersDetails,
        trackList: state.HomepageContentDetails,
        participantList: state.GeneralDetails,
        partnersList: state.PartnersDetails,
        sessionList: state.SessionDetails,
    };
};

export default connect(mapStateToProps, null)(MainPage);
