import axios from "axios";
const status = "Live";

export const serverLink =
    status === "Dev"
        ? "http://192.168.1.75:4480/"
        //: "https://conference-management-363620.uc.r.appspot.com/";
        : "https://api.bazeuniversity.edu.ng:5001/";
export const serverStatus = status;
export const paymentAPIKey = "pk_test_900f6286d49dd3d323d4b8e4530501fd060356d6";


export const sendMail = (receiver, subject, body) => {
    axios.post(`${serverLink}mail/send_mail`, { receiver: receiver, subject: subject, body: body }).then((res) => {
        if (res.data.message === "success") {
            console.log('sent')
        } else {
            console.log(res.data)
        }
    })
}
