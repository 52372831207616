import React, {Component} from 'react';
import {projectLogo} from "../../resources/constants";
import {Link} from "react-router-dom";

export default class Footer extends Component {
    render(){
        return (
            <>
                <section className="footer-section">
                    <div className="top"><a href="#" className="scrollToTop"><i className="fas fa-angle-up"></i></a>
                    </div>
                    <div className="footer-bottom bg-ash">
                        <div className="container container-1310">
                            <div className="row justify-content-lg-between justify-content-center align-items-center">
                                <div className="copyright wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".2s">
                                    <p>&copy;  2023 All right reserved. Designed By <a
                                        href="https://bazeuniversity.edu.ng">Baze University IT Team</a></p>
                                </div>
                                <div className="footer-social">
                                    <ul className="social-media d-flex flex-wrap mb-0">
                                        <li className="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".5s"><a target="_blank" href='https://www.facebook.com/BazeUniversity'><i className="fab fa-facebook-f"></i></a></li>
                                        <li className="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s"><a target="_blank" href='https://www.linkedin.com/school/bazeuniversity/mycompany/'><i className="fab fa-linkedin-in"></i></a></li>
                                        <li className="wow fadeInRight" data-wow-duration="1s" data-wow-delay=".3s"><a target="_blank" href='https://twitter.com/Baze_University'><i className="fab fa-twitter"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
