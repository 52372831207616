import {projectCode} from "../resources/constants";

export const setEventDetails = (p) => {
  return {
    type: `SET_${projectCode}_EVENT_DETAILS`,
    payload: p,
  };
};

export const setCommitteeDetails = (p) => {
  return {
    type: `SET_${projectCode}_COMMITTEE_DETAILS`,
    payload: p,
  };
};

export const setSpeakersDetails = (p) => {
  return {
    type: `SET_${projectCode}_SPEAKERS_DETAILS`,
    payload: p,
  };
};

export const setGeneralDetails = (p) => {
  return {
    type: `SET_${projectCode}_GENERAL_DETAILS`,
    payload: p,
  };
};

export const setHomepageContentDetails = (p) => {
  return {
    type: `SET_${projectCode}_HOMEPAGE_CONTENT_DETAILS`,
    payload: p,
  };
};

export const setPartnersDetails = (p) => {
  return {
    type: `SET_${projectCode}_PARTNERS_DETAILS`,
    payload: p,
  };
};


export const setSessionDetails = (p) => {
  return {
    type: `SET_${projectCode}_SESSION_DETAILS`,
    payload: p,
  };
};
