import React, { Component } from 'react';
import { useState } from 'react';
import banner from '../../images/banner2.png'
import placeholder from "../../images/speaker/placeholder.png";

export default function AgendaContent(props) {
    const [show, setShow] = useState(false)

    const openTab = (evt, tabName) => {
        console.log(tabName)
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        const check = props.session.filter(x => x.SessionTitle === tabName)
        if (check.length > 0) {
            document.getElementById(tabName).style.display = "block";
        }
        evt.currentTarget.className += " active";
    }

    let elmt = document.getElementsByClassName('.tab .col-lg-4.col-12');
    Array.from(elmt).forEach(function (element) {
        element.addEventListener('click', function (e) {
            console.log(e)
            let event = this;
            let bg = document.getElementsByClassName("event-schedule");
            bg.css('background-color', function () {
                return event.data('bgcolor');
            });
            bg.css("background-image", "url(" + event.data("bg") + ")");
        });
    });




    return (
        <>
            <section className="speaker-section style-1 padding-tb bg-ash" id="speakers">
                <div className="container container-1310 mt-2">
                    <div className="section-header sticky-widget">
                        <h2 className="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".1s">Event Agenda</h2>
                    </div>

                    <section className="event-schedule style-1 padding-tb " id="agenda" style={{ backgroundColor: '#7499ad' }}>
                        <div className="container container-1310">
                            <div className="section-wrapper">
                                <div className="tab row no-gutters padding-tb pt-2">
                                    {
                                        props.sessionList.length > 0 && props.sessionList.map((item, index) => {
                                            return (
                                                <div key={index} className="col  wow fadeInLeft" data-wow-duration="1s"
                                                    data-wow-delay=".1s" data-bgcolor="#A2DED0"
                                                    data-bg="https://picsum.photos/4835/3223?image=1076">
                                                    <div className="mlr-2">
                                                        <button className={`tablinks tablinks1 ${index.toString() === '0' ? 'active' : ""}`}
                                                            onClick={(e) => openTab(e, `${item.Title}`)}>Day - {index + 1}
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {/* {
                                        props.session.length > 0 && props.session.map((item, index)=> {
                                            return (
                                                <div key={index} className="col  wow fadeInLeft" data-wow-duration="1s"
                                                     data-wow-delay=".1s" data-bgcolor="#A2DED0"
                                                     data-bg="https://picsum.photos/4835/3223?image=1076">
                                                    <div className="mlr-2">
                                                        <button className={`tablinks tablinks1 ${index.toString() === '0' ? 'active' : ""}`}
                                                                onClick={(e)=>openTab(e, `${item.SessionTitle}`)}>Day - {index+1}
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    } */}
                                </div>
                                {
                                    props.session.length > 0 && props.session.map((item, key) => {
                                        return (
                                            <div key={key} id={item.SessionTitle} className={`tabcontent ${key.toString() === '0' ? 'active' : ""} `}>
                                                <div className="schedule-tabs wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
                                                    <div className="schedule-table table-responsive">
                                                        <h6 className="text-center">{item.SessionTitle}</h6>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th className="time">Schedule</th>
                                                                    <th className="session">Speaker</th>
                                                                    <th className="spekers">Session</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    props.session.length > 0 &&
                                                                    props.session.filter(e => e.SessionTitle === item.SessionTitle).length > 0 &&
                                                                    props.session.filter(e => e.SessionTitle === item.SessionTitle).map((item, i) => {
                                                                        return (
                                                                            <tr key={i}>
                                                                                <td className="time" data-title="Time">{item.StartTime} - {item.EndTime}</td>
                                                                                <td className="session" data-title="Session">{!item.TrackSpeaker ?  "N/A" : item.TrackSpeaker}</td>
                                                                                <td className="about" data-title="About Speaker">
                                                                                    <p>{item.Title}</p>
                                                                                    {/*<p className="td-icon"><i className="fas fa-angle-down"/></p>*/}
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }


                            </div>
                        </div>
                    </section>

                </div>
            </section>
        </>
    );
}
