import React, {Component} from 'react';
import img1 from "../../images/banner/shape/01.png";
import banner from "../../images/svg/banner.svg";
import {Link} from "react-router-dom";
import icon1 from "../../images/bbs/1.png";
import icon2 from "../../images/bbs/2.png";
import icon3 from "../../images/bbs/3.png";
import icon4 from "../../images/bbs/4.png";
import icon5 from "../../images/bbs/5.png";
import icon6 from "../../images/bbs/6.png";
import {serverLink} from "../../resources/url";

export default function EventInfo(props) {
        return (
            <>
                <section className="contact-section padding-tb bg-ash">
                    <div className="container container-1310">
                        <div className="section-header wow fadeInDown" data-wow-duration="1s" data-wow-delay=".1s">
                            <h2>Direction for the Venue</h2>
                            <p>It you have any question, please contact us through the numbers below. </p>
                        </div>
                        <div className="section-wrapper row">
                            <div className="col-lg-4 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".2s">
                                <div className="contact-info">
                                    <h5>Conference Location:</h5>
                                    <p>Baze University, Plot 686, Jabi Airport Road Bypass, Cadastral Zone, Abuja .</p>
                                    <h5>Contact No.</h5>
                                    <span>(+234) 987 654 3210</span>
                                    <h5>Time.</h5>
                                    <span>9.00 AM - 04.00 PM</span>
                                </div>
                            </div>
                            <div className="col-lg-8" >
                                <div className="mapouter">
                                    <div className="gmap_canvas">
                                        <iframe  height="400" id="gmap_canvas"
                                                src="https://maps.google.com/maps?q=2C44+7CR,%20Plot%20686,%20Jabi%20Airport%20Road%20Bypass,%20Cadastral%20Zone,%20Abuja&t=&z=17&ie=UTF8&iwloc=&output=embed"
                                                frameBorder="0" scrolling="no" marginHeight="0"
                                                marginWidth="0" style={{width: '100%'}}/>
                                        <a href="https://www.whatismyip-address.com/divi-discount/"/><br/>
                                        {/*<style>.mapouter{{position: 'relative', textAlign:'right',height:'500px',width:'600px'}}</style>*/}
                                        {/*<a href="https://www.embedgooglemap.net"></a>*/}
                                        {/*<style>.gmap_canvas{{overflow:'hidden',background:'none!important', height:'500px', width:'600px'}}</style>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className=" contact-section padding-tb" id="partners" style={{backgroundColor: '#fbfaf9'}}>

                    <div className="container container-1310">
                        <div className="section-header wow fadeInDown" data-wow-duration="1s" data-wow-delay=".1s">
                            <h2>Our Partners</h2>
                        </div>
                        <div className=" ">
                            <div className="row justify-content-center mt-5 pt-5 align-items-baseline  wow fadeInUp"
                                 data-wow-duration="1s" data-wow-delay=".2s">
                                {
                                    props.partnersList.length > 0 && props.partnersList.map((item, index)=> {
                                        return (
                                            <div key={index} className="col-md-2 col-4">
                                                <img src={`${serverLink}public/uploads/conference/partners/${item.ImagePath}`} alt={item.PartnerName}/>
                                            </div>
                                        )
                                    })
                                }


                            </div>
                        </div>
                    </div>
                </section>
            </>
        );

}



