import React, {Component} from 'react';
import placeholder from "../../images/banner/slider.png";
import {serverLink} from "../../resources/url";
import {Link} from "react-router-dom";
export default function Speakers(props) {
        return(
            <>
                <section className="speaker-section style-1 padding-tb bg-ash" id="speakers">
                    <div className="container container-1310 mt-2">
                        <div className="section-header sticky-widget">
                            <h2 className="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".1s">Keynote Speakers</h2>
                        </div>

                        <div className="section-wrapper d-flex flex-wrap justify-content-lg-center">
                            {
                                props.speakersList.length > 0 && props.speakersList.map((item, index)=>{
                                    return (
                                        <div key={index} className="speaker-item wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".1s">
                                            <div className="speaker-item-inner">
                                                <Link to={`/speaker/${item.EntryID}`} className="w-100">
                                                <div className="item-thumb"  style={{maxHeight: '380px', minHeight: '380px'}}>
                                                    <img src={!item.Photo ? placeholder : item.Photo.includes("simplefileupload") ? item.Photo : `${serverLink}public/uploads/conference/speakers/${item.Photo}` } alt="speaker"  style={{maxHeight: '380px', minHeight: '380px'}} />
                                                    <div className="item-overlay"/>
                                                </div>
                                                </Link>
                                                <div className="item-content" >
                                                    <Link to={`/speaker/${item.EntryID}`} style={{ fontSize: "18", fontWeight: '600', }}>{item.Title}. {item.FirstName} {!item.MiddleName || item.MiddleName === "null" ? "" : item.MiddleName} {item.LastName}</Link>
                                                    <p>{item.Designation} </p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }

                        </div>

                    </div>
                </section>
            </>
        );
}
