import React, {Component} from 'react';
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import {currencyConverter} from "../../../resources/constants";
import NonSpeakersForm from "./non-speakers-form";

export default function SpeakersForm (props) {

    const userPresentationType = (props) => {
        if (props.formData.ParticipantType === "Academic (Individual)") {
                return <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                        <select name="Region" id="Region"  className="form-control" value={props.formData.Region} onChange={props.onEdit} >
                            <option  value="">Participation Region</option>
                            <option amount="40000" data="25000"  value="Local (Nigerian) Participants">Local (Nigerian) Participants</option>
                            <option amount="100"  data="100" value="Foreign Participants">Foreign Participants</option>
                        </select>
                </div>
        } else if (props.formData.ParticipantType === "Organization") {
            return <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <select name="Region" id="Region"  className="form-control" value={props.formData.Region} onChange={props.onEdit} >
                    <option  value="">Participation Region</option>
                    <option amount="30000" data="250000"  value="Local (Nigerian) Participants">Local (Nigerian) Participants</option>
                    <option amount="500"  data="300" value="African Participants">African Participants</option>
                    <option amount="1000"  data="750" value="Foreign Participants">Foreign Participants</option>
                </select>
            </div>
        } else if (props.formData.ParticipantType === "NGO/BMO") {
            return <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <select name="Region" id="Region"  className="form-control" value={props.formData.Region} onChange={props.onEdit} >
                    <option  value="">Participation Region</option>
                    <option amount="25000" data="20000"  value="Local (Nigerian) Participants">Local (Nigerian) Participants</option>
                    <option amount="180"  data="120" value="African Participants">African Participants</option>
                    <option amount="2400"  data="2000" value="Foreign Participants">Foreign Participants</option>
                </select>
            </div>
        }else if (props.formData.ParticipantType === "Student") {
            return <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <select name="Region" id="Region"  className="form-control" value={props.formData.Region} onChange={props.onEdit} >
                    <option  value="">Participation Region</option>
                    <option amount="20000" data="15000"  value="Local (Nigerian) Participants">Local (Nigerian) Participants</option>
                    <option amount="35"  data="25" value="African Participants">African Participants</option>
                    <option amount="120"  data="60" value="Foreign Participants">Foreign Participants</option>
                </select>
            </div>
        }else{
            return <></>;
        }
    }

    return (
        <>
            <div className="col-lg-6 col-md-6 col-sm-12 form-group mt-3">
                <input type="text" name="FirstName" placeholder="First Name" value={ props.formData.FirstName} onChange={props.onEdit} required=""/>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 form-group  mt-3">
                <input type="text" name="MiddleName"  value={props.formData.MiddleName} onChange={props.onEdit}  placeholder="Middle Name"/>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <input type="text" name="LastName"  value={props.formData.LastName} onChange={props.onEdit}  placeholder="Surname" required=""/>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                <input type="text" name="Phone"  value={props.formData.Phone} onChange={props.onEdit}  placeholder="Phone" required=""/>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                <input type="email" name="Email"  value={props.formData.Email} onChange={props.onEdit}  placeholder="Email" />
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <input type="text" name="Organization"  value={props.formData.Organization} onChange={props.onEdit}  placeholder="Organization"/>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <select name="ModeOfParticipation"  id="ModeOfParticipation" className="form-control"  value={props.formData.ModeOfParticipation} onChange={props.onEdit} >
                    <option>Select Mode of Participation</option>
                    <option value="Online">Online</option>
                    <option value="Offline">Physical</option>
                </select>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <select name="Gender"  className="form-control" value={props.formData.Gender} onChange={props.onEdit} >
                    <option>Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                </select>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <select name="ParticipantType" id="ParticipantType"  className="form-control" value={props.formData.ParticipantType} onChange={props.onEdit} >
                    <option  value="">Select Participant Type</option>
                    <option  value="Academic (Individual)">Academic (Individual)</option>
                    <option  value="Organization">Organization</option>
                    <option  value="NGO/BMO">NGO/BMO</option>
                    <option  value="Student">Student</option>
                </select>
            </div>

            {
                userPresentationType(props)
            }

            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <span id="amount_payable" className="float-right badge badge-primary" style={{display: 'none'}}>Amount Payable: {currencyConverter(props.conferenceAmount, props.formData.Region === "Local (Nigerian) Participants" ? 'NGN' : 'USD')}</span>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <Select2
                    id="participant_session"
                    name="participant_session"
                    data={props.sessionList}
                    defaultValue={props.selectedSession}
                    multiple
                    className={"custom-select form-control"}
                    onSelect={props.onEdit}
                    options={{
                        placeholder: "Search Session",
                    }}
                />

            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 form-group" style={{marginTop: '20px'}}>
                <input type="password" name="Password"  autoComplete="off" autoSave="off"  value={props.formData.Password} onChange={props.onEdit}  placeholder="Enter your password"/>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 form-group" style={{marginTop: '20px'}}>
                <input type="password" name="CPassword"   autoComplete="off"  autoSave="off"   value={props.formData.CPassword} onChange={props.onEdit}  placeholder="Confirm your password"/>
            </div>

            <div className="submit-btn wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
                <div className="col-md-12">
                    <button className="btn-info w-100 form-control" type="submit" value="Submit-Messege" > Register </button>
                </div>
            </div>

        </>
    );
}
