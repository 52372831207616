import React, {Component, useEffect, useState} from 'react';
import {
    setSpeakersDetails
} from "../../actions/setactiondetails";
import {connect} from "react-redux";
import axios from "axios";
import {serverLink} from "../../resources/url";
import placeholder from "../../images/banner/slider.png";
import {Link} from "react-router-dom";
import {encryptData} from "../../resources/constants";
import {ThreeDots} from "react-loader-spinner";

function SpeakersPage (props) {
    const [isLoading, setIsLoading] = useState(props.speakersData.length > 0 ? false : true);
    const [speakersList, setSpeakersList] = useState(props.speakersData.length > 0 ? props.speakersData : []);

    const getData = async () => {
        await axios.get(`${serverLink}homepage/data`).then((result) => {
            const data = result.data;
            if (data.message === "success"){
                let speakersData = data.speakers;
                setSpeakersList(speakersData)
                props.setOnSpeakersDetails(speakersData);
            }
        }).catch((error) => {
            console.log("error", error);
        });
        setIsLoading(false);
    };
    useEffect(() => {
        getData();
    }, [""]);

    return (
        <>
            {
                isLoading ?
                    <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    margin: '-25px 0 0 -25px'
                }}>
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="red"
                            ariaLabel="loading"
                            wrapperStyle
                            wrapperClass
                        />
                    </div>

                        :  <section className="speaker-section style-1 padding-tb bg-ash">
                    <div className="container container-1310 mt-5">
                        <div className="section-header sticky-widget">
                            <h2 className="wow fadeInDown" data-wow-duration="1s" data-wow-delay=".1s">KEYNOTE SPEAKERS</h2>
                            <p className="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s"> </p>
                        </div>
                        <div className="section-wrapper d-flex flex-wrap justify-content-lg-center">
                            {
                                speakersList.length > 0 && speakersList.map((item, index)=>{
                                    return (
                                        <div key={index} className="speaker-item wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".1s">

                                            <div className="speaker-item-inner">
                                                <Link to={`/speaker/${item.EntryID}`} className="w-100">
                                                <div className="item-thumb" style={{maxHeight: '380px', minHeight: '380px'}}>
                                                    <img src={!item.Photo ? placeholder : item.Photo.includes("simplefileupload") ? item.Photo : `${serverLink}public/uploads/conference/speakers/${item.Photo}` } alt="speaker"  style={{maxHeight: '380px', minHeight: '380px'}}/>
                                                    <div className="item-overlay"/>

                                                </div>
                                                </Link>
                                                <div className="item-content">
                                                    <Link to={`/speaker/${item.EntryID}`} style={{ fontSize: "18", fontWeight: '600'}}>{item.Title}. {item.FirstName} {!item.MiddleName || item.MiddleName === "null" ? "" : item.MiddleName} {item.LastName}</Link>
                                                    <p>{item.Designation} </p>
                                                </div>
                                            </div>

                                        </div>

                                    );
                                })
                            }
                        </div>
                    </div>
                </section>
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        speakersData: state.SpeakersDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnSpeakersDetails: (p) => {
            dispatch(setSpeakersDetails(p));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SpeakersPage);
