import React, {Component, useEffect, useState} from 'react';
import {
    setSpeakersDetails
} from "../../actions/setactiondetails";
import {connect} from "react-redux";
import axios from "axios";
import {serverLink} from "../../resources/url";
import placeholder from "../../images/banner/slider.png";
import speaker from "../../images/speaker/placeholder.png";
import {Link, useParams} from "react-router-dom";
import {ThreeDots} from "react-loader-spinner";

function SpeakerProfile (props) {
    const { slug } = useParams();
    if (slug === "") window.href = '/';


    const [isLoading, setIsLoading] = useState(props.speakersList.length > 0 ? false : true);
    const [speakersData, setSpeakersData] = useState(props.speakersList.length > 0 ? props.speakersList.filter(item => item.EntryID.toString() === slug.toString() ) : []);
    const getData = async () => {
        await axios.get(`${serverLink}homepage/data`).then((result) => {
            const data = result.data;
            console.log(data)
            if (data.message === "success"){
                let speakers = data.speakers;
                setSpeakersData(speakers.filter(item => item.EntryID.toString() === slug.toString() ))
                props.setOnSpeakersDetails(speakers);
            }
        }).catch((error) => {
            console.log("error", error);
        });
        setIsLoading(false);
    };
    useEffect(() => {
        getData();
        console.log(speakersData)
    }, [""]);

    return (isLoading ?
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                margin: '-25px 0 0 -25px'
            }}>
                <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="red"
                    ariaLabel="loading"
                    wrapperStyle
                    wrapperClass
                />
            </div>

            :
        <>
            <p></p>
            <div class="page-header-section post-title style-1  padding-tb" style={{backgroundImage: `url(${placeholder})`, marginTop: '100px'}}>
                <div class="overlay">
                    <div class="page-header-content mt-5 pt-5 ">
                        <div class="container container-1310 mt-5 pt-5">
                            <div className="page-header-content-inner">
                                <div className="page-title">
                                    <span className="title-text">About The <span>Speaker</span></span>
                                </div>
                                <ol className="breadcrumb">
                                    <li>You Are Here :</li>
                                    <li><Link to="/">Home</Link></li>
                                    <li className="active">About The Speaker</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="speaker-profile padding-tb bg-ash">
                <div className="container container-1310">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="speaker-info">
                                <div className="personal-information">
                                    <div className="masonary-thumb">
                                        <img src={!speakersData[0].Photo ? speaker : speakersData[0].Photo.includes("simplefileupload") ? speakersData[0].Photo : `${serverLink}public/uploads/conference/speakers/${speakersData[0].Photo}` } className="img-thumbnail" alt="Speaker"/>
                                    </div>
                                    <h5>Personal Info</h5>
                                    <ul>
                                        <li><p>Name</p><span> {speakersData[0].Title} {speakersData[0].FirstName} {!speakersData[0].MiddleName || speakersData[0].MiddleName === "null" ? ""  : speakersData[0].MiddleName} {speakersData[0].LastName} </span></li>
                                        <li><p>Designation</p><span>{speakersData[0].Designation} </span></li>
                                        <li><p>Organization</p><span>{speakersData[0].Organization} </span></li>
                                        <li><p>Phone</p><span>{speakersData[0].Phone} </span></li>
                                        <li><p>Email</p><span>{speakersData[0].Email} </span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="speaker-details">
                                <div className="personal-articals">
                                    <h5>About {speakersData[0].FirstName} {!speakersData[0].MiddleName || speakersData[0].MiddleName === "null" ? ""  : speakersData[0].MiddleName} {speakersData[0].LastName}</h5>
                                    <p>{<blockquote dangerouslySetInnerHTML={{__html:speakersData[0].AboutSpeaker}}/>} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        committeeData: state.CommitteeDetails,
        speakersList: state.SpeakersDetails,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnSpeakersDetails: (p) => {
            dispatch(setSpeakersDetails(p));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SpeakerProfile);