import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Routers, Route, Routes } from "react-router-dom";
import Header from "../common/header/header";
import MainPage from "../mainpage/mainpage";
import Committee from "../committee/committee";
import Registration from "../registration/registration";
import About from "../about/about";
import AboutITRI from "../about/about-itri";
import Speakers from "../mainpage/speakers";
import SpeakersPage from "../speakers/speakers";
import SpeakerProfile from "../speakers/speaker-profile";
import Agenda from "../agenda/agenda";

export default function PageRoutes(props) {
  return (
    <Routers>
     <Header eventList={props.eventList} toggled={props.toggled} toggle={props.toggle} />

      <Routes>
        <Route path={"/"} element={<MainPage/>} />
        <Route path={"/home"} element={<MainPage/>} />
        <Route path={"/:slug"} element={<MainPage/>} />
        <Route path={"/committee"} element={<Committee/>} />
        <Route path={"/speakers"} element={<SpeakersPage/>} />
        <Route path={"/speaker/:slug"} element={<SpeakerProfile/>} />
        <Route path={"/register"} element={<Registration/>} />
        <Route path={"/about"} element={<About/>} />
        <Route path={"/agenda"} element={<Agenda/>} />
        <Route path={"/about-itri"} element={<AboutITRI/>} />

        <Route path={"*"} element={<MainPage />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />

      {/*<Footer />*/}
    </Routers>
  );
}
