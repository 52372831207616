import React, {Component, useEffect} from 'react';
import {Link} from "react-router-dom";
import {format_date} from "../../resources/constants";

export default function EventCount(props){

        return (
            <>
                <section className="conferench-section" style={{marginTop: '0px', paddingTop: '10px !important'}}>
                    <div className="conferench-area">
                        <div className="container container-1310">
                            <div className="conferench-counter">
                                <div className="conferench-bcr wow fadeInLeft" data-wow-duration="1s"
                                     data-wow-delay=".1s">
                                    <div className="conferench-counter-a">
                                        <ul id="countdown" className="countdown count-down d-flex flex-wrap"
                                            data-date={format_date(new Date(!props.eventsList.StartDate ?  Date.now() : props.eventsList.StartDate), 'MMMM d, yyyy hh:mm:ss:S')}>
                                            <li><span className="count-number days">00</span>
                                                <p>Days</p>
                                            </li>

                                            <li><span className="count-number hours">00</span>
                                                <p>Hours</p>
                                            </li>

                                            <li><span className="count-number minutes">00</span>
                                                <p>Minute</p>
                                            </li>

                                            <li><span className="count-number seconds">00</span>
                                                <p>Seconds</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="conferench-bcl wow fadeInRight" data-wow-duration="1s"
                                     data-wow-delay=".2s">
                                    <h3>Hurry up!</h3>
                                    <p>The  registration deadline is {format_date(new Date(!props.eventsList.StartDate ?  Date.now() : props.eventsList.StartDate), 'MMMM d, yyyy')}. </p>
                                    <Link to="/register" className="btn-defult">Register</Link>
                                    {/*<a className="btn-defult" style={{ backgroundColor: "red", color: "white" }}  target="_blank" href='http://meet.google.com/rwp-sahe-umc'>Join live event now!</a>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ticker  wow fadeInUp pb-3" data-wow-duration="1s" data-wow-delay=".1s" data-duration="70"
                         data-loop="2" data-effect="linear" style={{marginBottom: '200px'}}>
                        <span className=""> </span>
                    </div>
                </section>
            </>
        );
}
